import { Component, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '../search.service';
import { WebSocketService } from '../websockets.service';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit, OnDestroy {
  
  text: string = ''
  provider: string = ''
  nameMappings: {[key: string]: any} = {
    'CHATGPT': 'ChatGPT'
  }
  isLoading: boolean = true
  isShowing: boolean = false
  lastSearchId: any;
  ragItems: any;
  isRagParamInUrl: any;
  ragTimeout: any;

  private webSocketConnection: any;
  private webSocketSubscription: Subscription;

  constructor(private authService: AuthService, private searchService: SearchService, private webSocketService: WebSocketService, private route: ActivatedRoute) {
    this.text = ''
    this.provider = ''
    this.lastSearchId = false
    this.ragItems = []
    this.webSocketSubscription = new Subscription();
    this.isRagParamInUrl = false
    this.ragTimeout = 0
  }

  isNumberOrStringNumber(value: any): boolean {
    // Check if the type of the value is number
    if (typeof value === 'number') {
      return true;
    }
    // If it's a string, check if it can be successfully converted to a number
    if (typeof value === 'string') {
      // This handles cases where the string is not a valid number, e.g., "abc"
      const parsedNumber = parseInt(value);
      return !isNaN(parsedNumber) && isFinite(parsedNumber);
    }
    // Return false if the value is neither a number nor a string
    return false;
  }

  destroyWebSocketConnection() {
    if (this.webSocketConnection) {
      console.log("DESTROY SOCKET")
      this.webSocketConnection.complete();
      this.webSocketConnection = null;
    }
    this.webSocketService.disable()
  }

  async createWebSocketConnection(searchId: any, ragItems: any) {
    this.destroyWebSocketConnection()
    console.log("CREATE SOCKET")
    this.webSocketConnection = await this.webSocketService.init(this.authService.getToken(), searchId, ragItems)
  }

  initiateWebSocketDataFlow() {
    console.log("SEND DATA SOCKET")
    this.isLoading = true
    this.webSocketService.time.next(0)
    this.searchService.websocketLoading.next(true)
    const startTime = Date.now()
    this.webSocketService.send('')
    let websocketTimeout = this.webSocketService.timeout
    if(this.isNumberOrStringNumber(this.ragTimeout))
      websocketTimeout = this.ragTimeout
    this.webSocketService
      .receive()
      .pipe(timeout(websocketTimeout))
      .subscribe(
        ({ message, ai_model }) => {
          const responseTime = Date.now() - startTime;
          this.webSocketService.time.next(responseTime)
          this.isLoading = false
          this.searchService.websocketLoading.next(false)
          this.text = message;
          if(ai_model)
            this.webSocketService.aiModelChange.next(ai_model)
          this.destroyWebSocketConnection()
        },
        (error) => {
          if(error.name === 'TimeoutError') {
            this.webSocketService.send('stop');
            this.text = this.webSocketService.timeoutText;
            this.isLoading = false;
            this.searchService.websocketLoading.next(false);
            this.destroyWebSocketConnection();
          } else {
            console.error('WebSocket error:', error);
          }
        }
      );
  }


  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.isRagParamInUrl = params.get('rag')
      this.ragTimeout = params.get('rag_timeout')
    })
    this.searchService.selectedRagItems.subscribe((ragItems: any) => this.ragItems = ragItems)
    this.webSocketSubscription = this.webSocketService.webSocketChanged.subscribe(async ([isEnabled, isNewSearch]) => {
      if(isNewSearch)
        this.lastSearchId = false
      // Disable 'Generate AI response'
      if(!isEnabled) {
        this.isShowing = false
        console.log("Disable 'Generate AI response'")
        this.destroyWebSocketConnection()
      }
      // Enable 'Generate AI response'
      else {
        console.log("Enable 'Generate AI response'")
        if (this.lastSearchId) {
          await this.createWebSocketConnection(this.lastSearchId, this.ragItems)
          this.isShowing = true
          this.initiateWebSocketDataFlow()
        }
      }
    })
    this.searchService.currentSearchInfo.subscribe(async (info) => {
      if(info?.search?.id && !this.lastSearchId) {
        this.lastSearchId = info.search.id
        if(this.webSocketService.isEnabled()) {
          console.log('WebSocket enabled')
          await this.createWebSocketConnection(info.search.id, this.ragItems)
          this.isShowing = true
          this.initiateWebSocketDataFlow()
        }
      }
    });
    this.searchService.eraseEvent.subscribe((isErased) => {
      if(isErased) {
        this.isLoading = true
        this.isShowing = false
        this.lastSearchId = false
        this.webSocketService.changeStatus(false, true)
        this.ragItems = []
        this.webSocketService.time.next(0)
      }
    })
    this.searchService.isNewSearch.subscribe(isNewSearch => {
      if(isNewSearch) {
        this.isLoading = true
        this.isShowing = false
      }
    })
    this.searchService.isLoading.subscribe(isLoading => {
      this.lastSearchId = !isLoading ? this.lastSearchId : false
    });
    this.searchService.isNewSearch.subscribe(isNewSearch => this.webSocketService.time.next(0))
  }

  ngOnDestroy() {
    this.destroyWebSocketConnection()
    if(this.webSocketSubscription)
      this.webSocketSubscription.unsubscribe()
  }

}