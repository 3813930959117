<div #chatContainer class="chat-container">
  <div *ngFor="let message of parsedMessages" class="chat-message" [ngClass]="{'user-message': message.role === 'user', 'assistant-message': message.role === 'assistant'}">
      <span class="message-role">{{message.role === 'user' ? 'You:' : 'Swirl:'}}</span>
      <div class="message-content-container">
        <span class="message-content" [innerHTML]="message.content"></span>
        <ng-container *ngIf="message.additional_content?.sources?.length">
            <div class="message-sources">
                <app-chat-authorship-field 
                    *ngFor="let source of message.additional_content.sources" 
                    [provider]="source.description" 
                    [url]="source.url">
                </app-chat-authorship-field>
            </div>
        </ng-container>
        <ng-container *ngIf="message.additional_content?.ai_model">
          <div class="ai-model">
            <span class="stat">
              AI Summary generated by {{message.additional_content?.ai_model}}.
            </span>
          </div>
      </ng-container>
      </div>
  </div>
</div>
