<div class="row">
  <div class="solr-error col-auto" *ngIf="error && !isLoading">
    <span class="text-danger">{{error.msg}}</span>
  </div>
  <div class="col" *ngIf="qTime && !isLoading && numFound > 0">
    <span class="stat" *ngIf="(start + rows)<numFound">
      Showing results {{start + 1}} - {{start + rows}} of {{numFound | number}} found in {{qTimeSeconds}} seconds.
    </span>
    <span class="stat" *ngIf="(start+rows)>=numFound">
      Showing results {{start + 1}} - {{numFound | number}} of {{numFound | number}} found in {{qTimeSeconds}} seconds.
    </span>
    <span class="stat" *ngIf="qSummaryTimeSeconds > 0 && !aiModel">
      AI Summary generated in {{qSummaryTimeSeconds}} seconds.
    </span>
    <span class="stat" *ngIf="qSummaryTimeSeconds > 0 && aiModel">
      AI Summary generated by {{aiModel}} in {{qSummaryTimeSeconds}} seconds.
    </span>
  </div>
</div>

